import Layout from '../../components/layout/layout';
import React from 'react';

const SponsorUs = () => (
    <Layout>
        <h1>Sponsor Us</h1>
    </Layout>
);

export default SponsorUs;
